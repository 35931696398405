<template>
  <div>
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <a-row>
          <a-form-item
            class="ui-form__item"
            label="影院组织"
            name="organizationId"
            :rules="[{ required: true, message: '必选项不允许为空' }]"
          >
            <a-select
              placeholder="请选择"
              v-model:value="formState.organizationId"
              style="width: 180px"
              @change="getAllCinemaList"
            >
              <!--							 <a-select-option :value="0">全部</a-select-option>-->
              <a-select-option
                v-for="item in organizationList"
                :vlaue="item.id"
                :key="item.id"
              >
                {{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>

					<a-form-item class="ui-form__item" label="所属影院" name="cinemaId" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="线上会员卡号" name="cardNumber">
						<a-input placeholder="请输入" v-model:value="formState.cardNumber"></a-input>
					</a-form-item>
				</a-row>
				<a-row v-show="isShow">
					<a-form-item class="ui-form__item" label="订单来源" name="source">
						<a-select v-model:value="formState.source" style="width: 190px;" placeholder="请选择订单来源">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">微信小程序</a-select-option>
							<a-select-option :value="2">抖音小程序</a-select-option>
							<a-select-option :value="3">后台充值</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="是否售后" name="isAfterSale">
						<a-select v-model:value="formState.isAfterSale" style="width: 180px;" placeholder="请选择是否售后">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">是</a-select-option>
							<a-select-option :value="0">否</a-select-option>
						</a-select>
					</a-form-item>

          <a-form-item class="ui-form__item" label="会员昵称" name="nickname">
            <a-input
              placeholder="请输入"
              v-model:value="formState.nickname"
            ></a-input>
          </a-form-item>

          <a-form-item class="ui-form__item" label="会员手机号" name="phone">
            <a-input
              placeholder="请输入"
              v-model:value="formState.phone"
            ></a-input>
          </a-form-item>

          <a-form-item
            class="ui-form__item"
            label="交易流水号"
            name="outTradeNo"
          >
            <a-input
              placeholder="请输入"
              v-model:value="formState.outTradeNo"
            ></a-input>
          </a-form-item>


          <a-form-item class="ui-form__item" label="充值时间">
            <a-range-picker v-model:value="time" showTime />
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <span v-permission="['report_stored_excharge_export']">
              <ExportReport
                type="rechargeReport"
                :searchData="searchData"
              ></ExportReport>
            </span>
            <!-- <a-button v-permission="['report_exchange_consume_export']" type="primary" ghost>导出</a-button> -->
            <span v-if="!isShow" class="ui-showMore" @click="onDisplay"
              >展开<Icon icon="DownOutlined"></Icon
            ></span>
            <span v-else class="ui-showMore" @click="onDisplay"
              >收起<Icon icon="UpOutlined"></Icon
            ></span>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button
              style="margin-right: 10px"
              type="primary"
              html-type="submit"
              >搜索</a-button
            >
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 2200 }" tableLayout="auto">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'source'">
							<div>
								<a-tag color="green" v-if="record.source === 1">
									微信小程序
								</a-tag>
								<a-tag color="blue" v-else-if="record.source === 2"> 抖音小程序 </a-tag>
								<a-tag v-else>后台充值</a-tag>
							</div>
						</template>

						<template v-if="column.key === 'seatPrice'">
							{{ record.arrivalAmount - record.rechargeAmount }}
						</template>

						<template v-if="column.key == 'echangeTime'">
							<div> {{transDateTime(record.createTime)}} </div>
						</template>

						<template v-if="column.key === 'refund'">
							<div v-if="record.isAfterSale">
								<div>退款成功</div>
							</div>
							<span v-else>--</span>
						</template>

						<template v-if="column.key === 'action'">
							<div v-if="!record.isAfterSale" v-permission="['report_stored_excharge_refund']">
								<a-button type="link" size="small" @click="onRefundOk(record)">申请退款</a-button>
							</div>
							<div v-if="record.isAfterSale" style="padding-left: 10px;">--</div>
						</template>

					</template>
					<template #summary v-if="list.length">
						<a-table-summary>
							<a-table-summary-row>
								<a-table-summary-cell></a-table-summary-cell>
								<a-table-summary-cell></a-table-summary-cell>
								<a-table-summary-cell></a-table-summary-cell>
								<a-table-summary-cell></a-table-summary-cell>
								<a-table-summary-cell></a-table-summary-cell>
								<a-table-summary-cell>
									合计：{{ count.arrivalAmount || 0 }} 元
								</a-table-summary-cell>
								<a-table-summary-cell>
									合计：{{ count.rechargeAmount || 0 }} 元
								</a-table-summary-cell>
								<a-table-summary-cell></a-table-summary-cell>
								<a-table-summary-cell></a-table-summary-cell>
								<a-table-summary-cell></a-table-summary-cell>
								<a-table-summary-cell></a-table-summary-cell>
								<a-table-summary-cell></a-table-summary-cell>
							</a-table-summary-row>
						</a-table-summary>
					</template>
				</a-table>
			</div>
		</a-spin>

		<a-modal v-model:visible="refundModalVisible" title="申请退款" @ok="onRefundOk">
			<a-form ref="refundForm" name="refundForm" :model="refundModel">
				<a-form-item label="退款备注">
					<a-textarea placeholder="请输入退款备注"></a-textarea>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { rechargeStatement, rechargeStatementRefund } from '@/service/modules/report.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import moment from 'moment';
	import ExportReport from '@/components/exportReport/exportReport.vue';
	export default {
		components: {
			Icon,
			ExportReport
		},
		data() {
			return {
				loading: false,
				formState: {
					cinemaId: 0,
					isAfterSale: '',
					source: 0,
					type: 3,
				},
				time: [],
				showModal: false,
				list: [],
				columns: [{
					title: '影院名称',
					dataIndex: 'cinemaName'
				}, {
					title: '线上会员卡号',
					dataIndex: 'cardNumber'
				}, {
					title: '会员昵称',
					dataIndex: 'nickname'
				}, {
					title: '会员手机号',
					dataIndex: 'phone'
				}, {
					title: '订单来源',
					key: 'source',
					width: 120,
					align: 'center'
				}, {
					title: '充值到账金额(元)',
					dataIndex: 'arrivalAmount'
				}, {
					title: '实际支付金额(元)',
					dataIndex: 'rechargeAmount'
				}, {
					title: '赠送金额(元)',
					key: 'seatPrice'
				}, {
					title: '充值时间',
					key: 'echangeTime'
				}, {
					title: '线上会员卡金额(元)',
					dataIndex: 'afterBalance',
				}, {
					title: '交易流水号',
					dataIndex: 'outTradeNo'
				}, {
					title: '退款信息',
					key: 'refund'
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 100
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				isEdit: false,
				id: 0,
				searchData: {},
				isShow: false,
				organizationList: [],
				cinemaAllList: [],
				refundVisible: false,
				count: {},
				refundModalVisible: false,
				refundModel: {},
				refundItem: {}
			}
		},
		created(){
			// this.onSearch();
			this.getOrganizationList();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.source = this.searchData.source ? this.searchData.source : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.isAfterSale = this.searchData.isAfterSale !== '' ? this.searchData.isAfterSale : undefined;
				if (this.time && this.time.length) {
					this.searchData.startCreateTime = this.moment(this.time[0]).unix();
					this.searchData.endCreateTime = this.moment(this.time[1]).unix();
				}
				this.getData();
			},
			reset() {
				this.time = [];
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.pagination.total = 0;
				this.onSearch();
			},
			async getData(){
				this.loading = true;
				try {
					let {data} = await rechargeStatement({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					});
					this.loading = false;
					this.count = data.list[0];
					this.list = data.list.slice(1);
					this.pagination.total = data.totalCount;
				} catch (error) {
					this.loading = false;
				}
			},
			onDisplay(){
				this.isShow = !this.isShow;
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					this.loading = false;
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onRefund(record) {
				this.refundItem = JSON.parse(JSON.stringify(record));
				this.refundModel = {};
				this.refundModalVisible = true;
			},
			onRefundOk(record) {
				this.$confirm({
					title: '提示',
					content: '确定执行退款操作吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await rechargeStatementRefund({
								id: record.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('退款成功');
								this.refundModalVisible = false;
								this.getData();
							}
						} catch(e) {
							console.log(e)
							this.loading = false;
						}
					}
				})
			}
		}
	}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
.ui-batch {
  margin-right: 10px;
}
.ui-showMore {
  color: #6699ff;
  margin-left: 10px;
  cursor: pointer;
}
</style>
